<script setup
        lang="ts">
import { watch }         from 'vue';
import { useToastStore } from '@reshepe/stores';
import Toast             from 'primevue/toast';
import { useToast }      from 'primevue/usetoast';
import theme             from '../primevue/toast';

const toast = useToast();
const store = useToastStore();

const LIFETIME = 5000;

watch(
    store.toasts,
    (toasts) => {
        for (const t of toasts.filter((t) => t.active)) {
            toast.add({
                severity: t.type,
                summary:  t.content,
                life:     LIFETIME,
            });

            store.remove(t);
        }
    },
    { immediate: true },
);
</script>

<template>
    <Toast :pt="theme"
           position="bottom-center"
           class="toast" />
</template>

<style lang="scss">
[data-pc-name="toast"] {
    right:   0 !important;
    bottom:  0 !important;
    z-index: 9;
    width:   100%;
    opacity: 1;

    @include mobile-m {
        right:     20px !important;
        width:     25rem;
        min-width: 280px;
        max-width: 100%;
    }

    [data-pc-section="message"] {
        margin:        1rem 0 0;
        border-radius: 0;
        font-size:     0.95rem;

        [data-pc-section="container"] {
            [data-pc-section="content"] {
                position:    relative;
                align-items: center;

                [data-pc-section="icon"] {
                    color: var(--reshepe-color-white-700);
                }

                [data-pc-section="closebutton"] {
                    background: transparent;

                    [data-pc-section="closeicon"] {
                        color:      var(--reshepe-color-white-700);
                        transition: color 0.2s ease;
                    }

                    &:hover {
                        background: transparent;

                        [data-pc-section="closeicon"] {
                            color: var(--reshepe-color-white-500);
                        }
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                [data-pc-section="text"] {
                    color:       var(--reshepe-color-white-500);
                    font-family: var(--reshepe-font);
                    line-height: 1.4;

                    [data-pc-section="detail"] {
                        display: none;
                    }
                }
            }

            &.success {
                border-left: 4px solid var(--reshepe-color-success-500);

                [data-pc-section="icon"] {
                    color: var(--reshepe-color-success-500);
                }
            }

            &.error {
                border-left: 4px solid var(--reshepe-color-error-500);

                [data-pc-section="icon"] {
                    color: var(--reshepe-color-error-500);
                }
            }

            &.info {
                border-left: 4px solid var(--reshepe-color-info-500);

                [data-pc-section="icon"] {
                    color: var(--reshepe-color-info-500);
                }
            }

            &.warn {
                border-left: 4px solid var(--reshepe-color-warning-500);

                [data-pc-section="icon"] {
                    color: var(--reshepe-color-warning-500);
                }
            }
        }
    }
}
</style>
