<script setup
        lang="ts">
import { useAuthStore }      from '@stores/auth.store';
import { useThemeStore }     from '@reshepe/stores';
import { useResizeObserver } from '@vueuse/core';
import { useNProgress }      from '@vueuse/integrations/useNProgress';
import { onMounted, ref }    from 'vue';
import { throttle }          from 'lodash-es';
import { useRouter }         from 'vue-router';
import { WebVitals }         from '@reshepe-web-vitals/vue';
import Toast                 from '@reshepe/ui/toast.vue';

useThemeStore();
const { isLoading } = useNProgress();
const router = useRouter();
const authStore = useAuthStore();

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (from.path === '/auth/login' && to.meta.requires_auth && !authStore.is_logged_in) {
        next(from.fullPath);
    } else {
        if (to.path !== from.path) {
            isLoading.value = true;
        }

        if (to.meta.redirect) {
            next(to.meta.redirect);
            if (to.meta.redirect === from.path) {
                isLoading.value = false;
            }
        } else {
            if (to.meta.requires_auth && !authStore.is_logged_in) {
                next('/auth/login?r=' + to.fullPath);
            } else if (authStore.is_logged_in) {
                if (to.meta.hide_for_logged_in) {
                    next('/');
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
});

// router.afterEach((to, from, failure) => {
//     if (!failure) {
// usePageLeave(from.path);
// usePageView(to.path);
//     }
// });

window.addEventListener('vite:preloadError', (e: Event): void => {
    e.preventDefault();

    window.location.reload();
});

const loaded = ref<boolean>(false);
const dev = ref<boolean>(import.meta.env.MODE === 'development');
const preview = ref<boolean>(import.meta.env.VITE_VERCEL_ENV === 'preview');

const console_messages = (): void => {
    console.info(
        '01110100 01101000 01101001 01110011 00100000 01100111 01110101 01111001 00100000 01100110 01110101 01100011 01101011 01110011',
    );

    console.info(`%c
⠄⠄⠄⠄⠄⠄⠄⢀⣠⣶⣾⣿⣶⣦⣤⣀⠄⢀⣀⣤⣤⣤⣤⣄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⢀⣴⣿⣿⣿⡿⠿⠿⠿⠿⢿⣷⡹⣿⣿⣿⣿⣿⣿⣷⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⣾⣿⣿⣿⣯⣵⣾⣿⣿⡶⠦⠭⢁⠩⢭⣭⣵⣶⣶⡬⣄⣀⡀⠄⠄
⠄⠄⠄⡀⠘⠻⣿⣿⣿⣿⡿⠟⠩⠶⠚⠻⠟⠳⢶⣮⢫⣥⠶⠒⠒⠒⠒⠆⠐⠒
⠄⢠⣾⢇⣿⣿⣶⣦⢠⠰⡕⢤⠆⠄⠰⢠⢠⠄⠰⢠⠠⠄⡀⠄⢊⢯⠄⡅⠂⠄
⢠⣿⣿⣿⣿⣿⣿⣿⣏⠘⢼⠬⠆⠄⢘⠨⢐⠄⢘⠈⣼⡄⠄⠄⡢⡲⠄⠂⠠⠄
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣥⣀⡁⠄⠘⠘⠘⢀⣠⣾⣿⢿⣦⣁⠙⠃⠄⠃⠐⣀
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣋⣵⣾⣿⣿⣿⣿⣦⣀⣶⣾⣿⣿⡉⠉⠉
⣿⣿⣿⣿⣿⣿⣿⠟⣫⣥⣬⣭⣛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠄
⣿⣿⣿⣿⣿⣿⣿⠸⣿⣏⣙⠿⣿⣿⣶⣦⣍⣙⠿⠿⠿⠿⠿⠿⠿⠿⣛⣩⣶⠄
⣛⣛⣛⠿⠿⣿⣿⣿⣮⣙⠿⢿⣶⣶⣭⣭⣛⣛⣛⣛⠛⠛⠻⣛⣛⣛⣛⣋⠁⢀
⣿⣿⣿⣿⣿⣶⣬⢙⡻⠿⠿⣷⣤⣝⣛⣛⣛⣛⣛⣛⣛⣛⠛⠛⣛⣛⠛⣡⣴⣿
⣛⣛⠛⠛⠛⣛⡑⡿⢻⢻⠲⢆⢹⣿⣿⣿⣿⣿⣿⠿⠿⠟⡴⢻⢋⠻⣟⠈⠿⠿
⣿⡿⡿⣿⢷⢤⠄⡔⡘⣃⢃⢰⡦⡤⡤⢤⢤⢤⠒⠞⠳⢸⠃⡆⢸⠄⠟⠸⠛⢿
⡟⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠁⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢸
`, 'color: green;');

    if (preview.value) {
        console.log(import.meta.env);
    }
};

const resize = (): void => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

onMounted(async () => {
    useResizeObserver(document.body, throttle(resize, 300));
    document.getElementById('app')?.classList.add('loaded');
    document.getElementById('loader')?.remove();

    if (!dev.value) {
        console_messages();
    }

    if (authStore.is_logged_in) {
        await authStore.me();
    }

    loaded.value = true;
});
</script>

<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
    <Toast />
    <WebVitals api-key="pk_7bb5af98-f804-4a71-9673-be36ba7e3371"
               :development="dev || preview"
               report-soft-navigation />
</template>

<style lang=scss>
@import "@reshepe/scss/main";

html {
    display:          block;
    overflow-y:       auto;
    height:           calc(100%);
    background-color: var(--reshepe-color-bg-500);
    color:            var(--reshepe-color-text-500);

    body {
        overflow-x:                  hidden;
        height:                      100%;
        margin:                      0;
        font-size:                   16px;
        font-family:                 v-sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        line-height:                 1.6;
        text-size-adjust:            100%;
        -webkit-tap-highlight-color: transparent;

        &::-webkit-scrollbar {
            width: 2px;
        }

        &::-webkit-scrollbar-track {
            border-radius:    0;
            background-color: transparent;
            box-shadow:       inset 0 0 1px transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius:    0;
            background-color: var(--reshepe-color-primary-500);
            box-shadow:       inset 0 0 1px var(--reshepe-color-primary-500);
        }

        #app {
            position:         relative;
            display:          none;
            min-height:       calc((var(--vh, 1vh) * 100));
            background-color: var(--reshepe-color-bg-500);
            color:            var(--reshepe-color-text-500);
            font-size:        16px;
            font-family:      var(--reshepe-font);

            &.loaded {
                display: block;
                height:  calc((var(--vh, 1vh) * 100));
            }

            #app-container {
                position: relative;
                width:    100%;
            }
        }
    }
}

#nprogress {
    pointer-events: none;

    .bar {
        position:   fixed;
        top:        0;
        left:       0;
        z-index:    1031;
        width:      100%;
        height:     2px;
        background: var(--reshepe-color-info-500);
    }

    .peg {
        position:   absolute;
        right:      0;
        display:    block;
        width:      100px;
        height:     100%;
        box-shadow: 0 0 10px var(--reshepe-color-info-500), 0 0 5px var(--reshepe-color-info-500);
        opacity:    1;
        transform:  rotate(3deg) translate(0, -4px);
    }
}

.nprogress-custom-parent {
    position: relative;
    overflow: hidden;

    #nprogress {
        .bar {
            position: absolute;
        }
    }
}

@include light-theme {
    body {
        ::-webkit-scrollbar-thumb {
            background-color: var(--reshepe-color-secondary-700);
            box-shadow:       inset 0 0 1px var(--reshepe-color-secondary-700);
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--reshepe-color-secondary-700);
            box-shadow:       inset 0 0 1px var(--reshepe-color-secondary-700);
        }
    }
}
</style>
