import { createApp }        from 'vue';
import { router }           from './router';
import { head }             from './head';
import { pinia }            from './pinia';
import App                  from './App.vue';
import PrimeVue             from 'primevue/config';
import ToastService         from 'primevue/toastservice';
import ConfirmationService  from 'primevue/confirmationservice';
import BadgeDirective       from 'primevue/badgedirective';
import Tooltip              from 'primevue/tooltip';
import { default as theme } from '@reshepe/primevue';
import * as Sentry          from '@sentry/vue';

import 'virtual:uno.css';

const app = createApp(App);

const isProduction = import.meta.env.VITE_VERCEL_ENV === 'production';

if (isProduction) {
    Sentry.init({
        app,
        release:                  import.meta.env.VERCEL_GIT_COMMIT_SHA,
        dsn:                      'https://280a78b7cc4f811542a827c81b77c4fa@o4506913952563200.ingest.us.sentry.io/4507295271616512',
        integrations:             [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText:   false,
                blockAllMedia: false,
            }),
        ],
        tracesSampleRate:         1.0,
        replaysSessionSampleRate: 0.05,
        replaysOnErrorSampleRate: 1.0,
    });
}

app
    .use(ToastService)
    .use(ConfirmationService)
    .use(router)
    .use(head)
    .use(pinia)
    .use(PrimeVue, {
        unstyled: true,
        pt:       theme,
        ripple:   false,
        locale:   {
            startsWith:            'Starts with',
            contains:              'Contains',
            notContains:           'Not contains',
            endsWith:              'Ends with',
            equals:                'Equals',
            notEquals:             'Not equals',
            noFilter:              'No Filter',
            lt:                    'Less than',
            lte:                   'Less than or equal to',
            gt:                    'Greater than',
            gte:                   'Greater than or equal to',
            dateIs:                'Date is',
            dateIsNot:             'Date is not',
            dateBefore:            'Date is before',
            dateAfter:             'Date is after',
            clear:                 'Clear',
            apply:                 'Apply',
            matchAll:              'Match All',
            matchAny:              'Match Any',
            addRule:               'Add Rule',
            removeRule:            'Remove Rule',
            accept:                'Yes',
            reject:                'No',
            choose:                'Choose',
            upload:                'Upload',
            cancel:                'Cancel',
            completed:             'Completed',
            pending:               'Pending',
            dayNames:              ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort:         ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin:           ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames:            ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort:       ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            chooseYear:            'Choose Year',
            chooseMonth:           'Choose Month',
            chooseDate:            'Choose Date',
            prevDecade:            'Previous Decade',
            nextDecade:            'Next Decade',
            prevYear:              'Previous Year',
            nextYear:              'Next Year',
            prevMonth:             'Previous Month',
            nextMonth:             'Next Month',
            prevHour:              'Previous Hour',
            nextHour:              'Next Hour',
            prevMinute:            'Previous Minute',
            nextMinute:            'Next Minute',
            prevSecond:            'Previous Second',
            nextSecond:            'Next Second',
            am:                    'am',
            pm:                    'pm',
            today:                 'Today',
            weekHeader:            'Wk',
            firstDayOfWeek:        1,
            dateFormat:            'dd/mm/yy',
            weak:                  'Weak',
            medium:                'Medium',
            strong:                'Strong',
            passwordPrompt:        'Enter a password',
            emptyFilterMessage:    'No results found', // @deprecated Use 'emptySearchMessage' option instead.
            searchMessage:         '{0} results are available',
            selectionMessage:      '{0} items selected',
            emptySelectionMessage: 'No selected item',
            emptySearchMessage:    'No results found',
            emptyMessage:          'No available options',
            aria:                  {
                trueLabel:   'True',
                falseLabel:  'False',
                nullLabel:   'Not Selected',
                star:        '1 star',
                stars:       '{star} stars',
                selectAll:   'All items selected',
                unselectAll: 'All items unselected',
                close:       'Close',
                previous:    'Previous',
                next:        'Next',
                navigation:  'Navigation',
            },
        },
    })
    .directive('badge', BadgeDirective)
    .directive('tooltip', Tooltip)
    .mount('#app');
