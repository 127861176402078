var e={root:({context:r})=>({class:["font-bold","text-xs leading-5","flex items-center justify-center","text-center","absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 origin-top-right","m-0",{"p-0":r.nogutter||r.dot,"px-2":!r.nogutter&&!r.dot,"min-w-[0.5rem] w-2 h-2":r.dot,"min-w-[1.5rem] h-6":!r.dot},{"rounded-full":r.nogutter||r.dot,"rounded-[10px]":!r.nogutter&&!r.dot},"text-primary-inverse",{"bg-primary":!r.info&&!r.success&&!r.warning&&!r.danger&&!r.help&&!r.secondary,"bg-surface-500 dark:bg-surface-400":r.secondary,"bg-green-500 dark:bg-green-400":r.success,"bg-blue-500 dark:bg-blue-400":r.info,"bg-orange-500 dark:bg-orange-400":r.warning,"bg-purple-500 dark:bg-purple-400":r.help,"bg-red-500 dark:bg-red-400":r.danger},"badge"]})},a={css:`
    *[data-pd-ripple="true"]{
        overflow: hidden;
        position: relative;
    }
    span[data-p-ink-active="true"]{
        animation: ripple 0.4s linear;
    }
    @keyframes ripple {
        100% {
            opacity: 0;
            transform: scale(2.5);
        }
    }

    .progress-spinner-circle {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: 0;
        animation: p-progress-spinner-dash 1.5s ease-in-out infinite, p-progress-spinner-color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    @keyframes p-progress-spinner-dash{
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }
    @keyframes p-progress-spinner-color {
        100%, 0% {
            stroke: #ff5757;
        }
        40% {
            stroke: #696cff;
        }
        66% {
            stroke: #1ea97c;
        }
        80%, 90% {
            stroke: #cc8925;
        }
    }

    .progressbar-value-animate::after {
        will-change: left, right;
        animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }
    .progressbar-value-animate::before {
        will-change: left, right;
        animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    @keyframes p-progressbar-indeterminate-anim {
        0% {
            left: -35%;
            right: 100%;
        }
        60% {
            left: 100%;
            right: -90%;
        }
        100% {
            left: 100%;
            right: -90%;
        }
    }

    .p-fadein {
        animation: p-fadein 250ms linear;
    }

    @keyframes p-fadein {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`},s={root:{class:["block absolute bg-surface-0/50 rounded-full pointer-events-none"],style:"transform: scale(0)"}},i={root:({context:r})=>({class:["absolute","p-tooltip","shadow-md","p-fadein",{"p-tooltip-top":r?.top,"p-tooltip-right":r?.right,"p-tooltip-bottom":r?.bottom,"p-tooltip-left":r?.left,"py-0 px-1":r?.right||r?.left||!r?.right&&!r?.left&&!r?.top&&!r?.bottom,"py-1 px-0":r?.top||r?.bottom}]}),arrow:({context:r})=>({class:["absolute","w-0","h-0","border-transparent","border-solid",{"border-y-[10px] border-r-[10px] border-l-0 border-r-surface-700":r?.right||!r?.right&&!r?.left&&!r?.top&&!r?.bottom,"border-y-[10px] border-l-[10px] border-r-0 border-l-surface-700":r?.left,"border-x-[10px] border-t-[10px] border-b-0 border-t-surface-700":r?.top,"border-x-[10px] border-b-[10px] border-t-0 border-b-surface-700":r?.bottom},{"-mt-[10px] top-1/2":r?.right||r?.left||!r?.right&&!r?.left&&!r?.top&&!r?.bottom,"-ml-[10px] left-1/2":r?.top||r?.bottom}]}),text:{class:["p-3","bg-surface-700","text-white","leading-none","rounded-md","whitespace-pre-line","break-words","p-tooltip-text"]}},o={global:a,directives:{badge:e,ripple:s,tooltip:i}};export{o as default};
